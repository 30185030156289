<!-- =========================================================================================
    File Name: Calendar.vue
    Description: Calendar App
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
    Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div id="calendar-app">
    <div class="vx-card app-fixed-height">
      <VuePerfectScrollbar class="scroll-area" :settings="settings">
        <full-calendar
          class="w-full select-none"
          :events="calendarEvents"
          locale="en"
          @dayClick="showModal"
          @eventClick="openEditEvent"
        >
          <!-- HEADER LEFT -->
          <div slot="fc-header-left" class="flex flex-wrap sm:justify-start justify-center">
            <!-- <div
              v-for="(label, index) in calendarLabels"
              :key="index"
              class="flex items-center mr-4 mb-2"
            >
              <div class="h-3 w-3 inline-block rounded-full mr-2" :class="'bg-' + label.color"></div>
              <span>{{ label.text }}</span>
            </div>-->
            <!-- <div class="flex items-center mr-4 mb-2">
              <div class="h-3 w-3 inline-block rounded-full mr-2 bg-primary"></div>
              <span>None</span>
            </div>-->
          </div>

          <!-- HEADER RIGHT -->
        </full-calendar>
      </VuePerfectScrollbar>

      <!-- ADD EVENT -->
      <vs-popup
        class="calendar-event-dialog"
        title="Add Day's Budget"
        vs-accept-text="Add Event"
        @vs-accept="addEvent"
        fullscreen
        :vs-is-valid="validForm"
        :active.sync="popupActive"
      >
        <!-- <div class="calendar__label-container flex">
          <vs-chip
            v-if="labelLocal != 'none'"
            class="text-white"
            :class="'bg-' + labelColor(labelLocal)"
          >{{ labelLocal }}</vs-chip>

          <vs-dropdown vs-custom-content vs-trigger-click class="ml-auto my-2 cursor-pointer">
            <feather-icon icon="TagIcon" svgClasses="h-5 w-5" class="cursor-pointer" @click.prevent></feather-icon>

            <vs-dropdown-menu style="z-index: 200001">
              <vs-dropdown-item
                v-for="(label, index) in calendarLabels"
                :key="index"
                @click="labelLocal = label.value"
              >
                <div class="h-3 w-3 inline-block rounded-full mr-2" :class="'bg-' + label.color"></div>
                <span>{{ label.text }}</span>
              </vs-dropdown-item>

              <vs-dropdown-item @click="labelLocal = 'none'">
                <div class="h-3 w-3 mr-1 inline-block rounded-full mr-2 bg-primary"></div>
                <span>None</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>-->
        <div class="vx-col w-full">
          <div class="mx-0 row mb-6">
            <div class="col-6">
              <multiselect
                v-model="projects_value"
                track-by="project_id"
                label="project_name"
                :options="projects"
                name="Project Name"
                placeholder="Select Project"
                :searchable="true"
                :allow-empty="false"
                open-direction="bottom"
                deselect-label="Can't remove this value"
              >
                <template slot="singleLabel" slot-scope="{ option }">{{ option.project_name }}</template>
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('Project Name')"
                >{{ errors.first('Project Name') }}</span>
              </multiselect>
              <!-- <small class="date-label">Date</small>
              <datepicker name="start-date" v-model="datepicked">{{datepicked}}</datepicker>-->
            </div>
            <div class="col-4"></div>
            <div class="col-1">
              <div slot="fc-header-right" class="flex justify-end">
                <vs-button
                  icon-pack="feather"
                  icon="icon-plus"
                  @click="promptAddNewEvent(new Date())"
                >Add</vs-button>
              </div>
            </div>
          </div>
          <div v-for="(singleBudget, index ) in budgetArray" :key="index" class="mx-0 row mb-6">
            <div class="col-3">
              <multiselect
                v-model="category_value"
                track-by="category_id"
                label="category_name"
                @input="({category_id})=> this.category_id = category_id"
                :options="categoriesByProject"
                name="Category"
                :searchable="true"
                :allow-empty="true"
                open-direction="bottom"
                :clear-on-select="false"
                :preselect-first="true"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  {{ option.category_name }}
                  <span
                    class="text-primary"
                    v-show="option.parent_category_name != null"
                  >{{" [" + option.parent_category_name +"]" }}</span>
                </template>
              </multiselect>
            </div>
            <div class="col-4">
              <multiselect
                v-model="category_value"
                track-by="category_id"
                label="category_name"
                @input="({category_id})=> this.category_id = category_id"
                :options="categoriesByProject"
                name="Category"
                :searchable="true"
                :allow-empty="true"
                :clear-on-select="false"
                :preselect-first="true"
                open-direction="bottom"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  {{ option.category_name }}
                  <span
                    class="text-primary"
                    v-show="option.parent_category_name != null"
                  >{{" [" + option.parent_category_name +"]" }}</span>
                </template>
              </multiselect>
            </div>
            <div class="col-2">
              <vs-input
                name="event-name"
                v-validate="'required'"
                class="w-full"
                label-placeholder="Budget amt."
                v-model="singleBudget.budget_amt"
                type="number"
              ></vs-input>
            </div>
            <div class="col-2">
              <vs-input
                name="event-name"
                v-validate="'required'"
                class="w-full"
                label-placeholder="Actual amt."
                v-model="singleBudget.actual_amt"
                type="number"
                :disabled="disabledFrom"
              ></vs-input>
            </div>
            <div class="col-1">
              <button @click="onRemoveBudget()">X</button>
            </div>
          </div>
        </div>
        <!-- 
        <vs-textarea rows="5" label="Add description" v-model="desc" />-->
      </vs-popup>

      <!-- EDIT EVENT -->
      <!-- <vs-prompt
        class="calendar-event-dialog"
        vs-title="Edit Event"
        vs-accept-text="Submit"
        vs-cancel-text="Remove"
        vs-button-cancel="border"
        @vs-cancel="removeEvent"
        @vs-accept="editEvent"
        :vs-is-valid="validForm"
        :vs-active.sync="activePromptEditEvent"
      >
        <div class="calendar__label-container flex">
          <vs-chip
            v-if="labelLocal != 'none'"
            class="text-white"
            :class="'bg-' + labelColor(labelLocal)"
          >{{ labelLocal }}</vs-chip>

          <vs-dropdown vs-custom-content class="ml-auto my-2 cursor-pointer">
            <feather-icon icon="TagIcon" svgClasses="h-5 w-5" @click.prevent></feather-icon>

            <vs-dropdown-menu style="z-index: 200001">
              <vs-dropdown-item
                v-for="(label, index) in calendarLabels"
                :key="index"
                @click="labelLocal = label.value"
              >
                <div class="h-3 w-3 inline-block rounded-full mr-2" :class="'bg-' + label.color"></div>
                <span>{{ label.text }}</span>
              </vs-dropdown-item>

              <vs-dropdown-item @click="labelLocal = 'none'">
                <div class="h-3 w-3 mr-1 inline-block rounded-full mr-2 bg-primary"></div>
                <span>None</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>

        <vs-input
          name="event-name"
          v-validate="'required'"
          class="w-full"
          label-placeholder="Event Title"
          v-model="title"
        ></vs-input>
        <div class="my-4">
          <small class="date-label">Start Date</small>
          <datepicker :disabledDates="disabledDatesFrom" name="start-date" v-model="start"></datepicker>
        </div>
        <div class="my-4">
          <small class="date-label">End Date</small>
          <datepicker :disabledDates="disabledDatesTo" name="end-date" v-model="end"></datepicker>
        </div>
        <vs-textarea rows="5" label="Add description" v-model="desc" />
      </vs-prompt>-->
    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import VuePerfectScrollbar from "vue-perfect-scrollbar";

export default {
  data() {
    return {
      popupActive: false,
      datepicked: "",

      projects: [],
      projects_value: [],
      categoriesByProject: [],
      category_value: [],
      actual: "",
      budget: "",
      desc: "",
      id: 0,
      disabledFrom: false,
      labelLocal: "none",
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.3,
      },
      activePromptAddEvent: false,
      activePromptEditEvent: false,
      budgetArray: [
        {
          category_id: "",
          category_name: "",
          sub_category_id: "",
          sub_category_name: "",
          budget_amt: "",
          actual_amt: "",
        },
      ],
    };
  },
  computed: {
    calendarEvents() {
      return this.$store.state.calendar.calendarEvents;
    },
    validForm() {
      return (
        this.title != "" &&
        this.start != "" &&
        this.end != "" &&
        Date.parse(this.end) - Date.parse(this.start) >= 0
      );
    },
    disabledDatesTo() {
      return { to: new Date(this.start) };
    },
    disabledDatesFrom() {
      return { from: new Date(this.end) };
    },
    calendarLabels() {
      return this.$store.state.calendar.calendarLabels;
    },
    labelColor() {
      return (label) => {
        if (label == "business") return "success";
        else if (label == "work") return "warning";
        if (label == "personal") return "danger";
        if (label == "none") return "primary";
      };
    },
  },
  methods: {
    addEvent() {
      const obj = {
        title: this.title,
        start: this.start,
        end: this.end,
        label: this.labelLocal,
        desc: this.desc,
      };
      obj.cssClass = "event-" + this.labelColor(this.labelLocal);
      this.$store.dispatch("calendar/addEventToCalendar", obj);
    },
    showModal(e) {
      this.$store.dispatch("setBudgetDate", e);
      this.$router.push({
        name: "dailyBudget",
      });
    },
    clearFields() {
      this.title = this.end = this.desc = "";
      this.id = 0;
      this.labelLocal = "none";
    },
    addNewEventDialog(date) {
      this.clearFields();
      this.start = date;
      this.end = date;
      this.activePromptAddEvent = true;
    },
    openAddNewEvent(date) {
      this.disabledFrom = true;
      this.addNewEventDialog(date);
    },
    promptAddNewEvent(date) {
      this.disabledFrom = false;
      this.budgetArray.push({
        category_id: "",
        category_name: "",
        sub_category_id: "",
        sub_category_name: "",
        budget_amt: "",
        actual_amt: "",
      });
      this.addNewEventDialog(date);
    },
    onRemoveBudget(index) {
      this.budgetArray.splice(index, 1);
    },
    openEditEvent(event) {
      const e = this.$store.getters["calendar/eventById"](event.id);
      this.id = e.id;
      this.title = e.title;
      this.start = e.start;
      this.end = e.end;
      this.desc = e.desc;
      this.activePromptEditEvent = true;
    },
    editEvent() {
      const obj = {
        id: this.id,
        title: this.title,
        start: this.start,
        end: this.end,
        label: this.labelLocal,
        desc: this.desc,
      };
      obj.cssClass = "event-" + this.labelColor(this.labelLocal);
      this.$store.dispatch("calendar/ediCalendarEvent", obj);
    },
    removeEvent() {
      this.$store.dispatch("calendar/removeCalendarEvent", this.id);
    },
  },
  components: {
    "full-calendar": require("vue-fullcalendar"),
    Datepicker,
    VuePerfectScrollbar,
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/vuesax/apps/calendar.scss";
</style>
